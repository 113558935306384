<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-office-building-plus
      </v-icon> New Project Gate
    </h2>
    <v-card
      class="my-base-vcard-style"
    >
      <br>
      <v-card
        class="my-vcard-style"
      >
        <v-container fluid>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="projectGateName"
                  label="Project Gate*"
                  class="purple-input"
                  hint="Eg:- West Campus Gate"
                  :rules="[(v) => !!v || 'This field is required']"
                  persistent-hint
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="selectedProject"
                  :items="projectsList"
                  label="Select Project*"
                  item-text="name"
                  item-value="id"
                  prepend-icon="mdi-sitemap-outline"
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  v-model="locationTimeZone"
                  :items="timeZonesByName"
                  label="Time Zone*"
                  prepend-icon="mdi-clipboard-text-clock-outline"
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  id="map-button"
                  outlined
                  class="mt-2"
                  color="#FF3700"
                  depressed
                  default
                  @click="openGoogleMaps()"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-google-maps
                  </v-icon>
                  Mark Gate*
                </v-btn>
              </v-col>
              <div
                v-if="isProjectGateMarked"
                class="border-content d-flex"
              >
                <v-col
                  cols="3"
                  class="pa-0 d-flex align-center"
                >
                  <v-img
                    :src="mapImg"
                    height="100"
                    width="150"
                    class="d-flex align-center ml-3"
                  />
                </v-col>
                <v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="d-flex align-left flex-column"
                  >
                    <div class="font-weight-bold font-style-text2">
                      {{ markedProjectGateDetails.name }}
                    </div>
                    <div class="font-style-text ml-1">
                      {{ markedProjectGateDetails.formatted_address }}
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="d-flex align-center pa-0"
                  >
                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div class="codn-div">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-chip
                              v-if="!isProjectGateMarked"
                              color="#37474F"
                              outlined
                              class="codn-chip"
                            >
                              Lat: N/A
                            </v-chip>
                            <v-chip
                              v-else
                              color="#37474F"
                              outlined
                              class="codn-chip"
                            >
                              Lat: {{ markedProjectGateDetails.geometry.location.lat() }}
                            </v-chip>
                          </span>
                        </div>
                      </template>
                      <span>Coordinate values are based on the location you mark on the Google Maps and are highly crucial in monitoring user Check-In/Check-Out,<br> so always choose a location that is close/exact to your required site address.</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div class="codn-div">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-chip
                              v-if="!isProjectGateMarked"
                              color="#37474F"
                              outlined
                              class="codn-chip"
                            >
                              Long: N/A
                            </v-chip>
                            <v-chip
                              v-else
                              color="#37474F"
                              outlined
                              class="codn-chip"
                            >
                              Long: {{ markedProjectGateDetails.geometry.location.lng() }}
                            </v-chip>
                          </span>
                        </div>
                      </template>
                      <span>Coordinate values are based on the location you mark on the Google Maps and are highly crucial in monitoring user Check-In/Check-Out,<br> so always choose a location that is close/exact to your required site address.</span>
                    </v-tooltip>
                  </v-col>
                </v-col>
              </div>
            </v-row>
          </v-form>
        </v-container>
        <br>
        <p class="mandatory-text">
          *Indicates required field
        </p>
        <v-card-actions>
          <v-btn
            color="#000"
            @click="addProjectGate()"
          >
            Add
          </v-btn>
          <v-btn
            color="#aaa"
            style="margin-left:10px"
            @click="cancelProjectGateForm()"
          >
            <span class="black--text">Cancel</span>
          </v-btn>
        </v-card-actions>
      </v-card>
      <br>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>

<script>
 import spinner from 'src/views/dashboard/component/SpinnerCentre';
 import Constants from 'src/constants';

 export default {
    name: 'AddProjectGate',
    components: {
      'centre-spinner': spinner,
    },
    data: () => ({
      loading: false,
      valid: false,
      projectGateName: '',
      locationTimeZone: '',
      address: {},
      selectedProject: '',
      mapImg: require('@/assets/location.png'),
    }),
    computed: {
      projectsList () {
        return this.$store.getters['projects/getProjectsList'];
      },
      savedProjectGateDetails () {
        return this.$store.getters['projectgates/getSavedProjectGateDetails'];
      },
      markedProjectGateDetails () {
        return this.$store.getters['projectgates/getMarkedProjectGateDetails'];
      },
      isProjectGateMarked () {
        if (Object.keys(this.markedProjectGateDetails).length > 0) {
          return true;
        }
        return false;
      },
      timeZones () {
        return this.$store.getters['userprofile/getTimeZones'];
      },
      timeZonesByName () {
        return Object.keys(this.timeZones).map((key) => ({ text: key, value: this.timeZones[key] }));
      },
    },
    async mounted () {
      this.loading = true;
      await this.fillData();
      await this.getAllTimeZone();
      await this.getProjectsList();
      this.loading = false;
    },
    methods: {
      async getProjectsList () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectsList').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      fillData () {
        if (Object.keys(this.savedProjectGateDetails).length === 0) {
          return;
        }
        this.projectGateName = this.savedProjectGateDetails.projectGateName;
        this.locationTimeZone = this.savedProjectGateDetails.locationTimeZone;
        this.selectedProject = this.savedProjectGateDetails.selectedProject;
      },
      getAllTimeZone () {
        this.$store.dispatch('userprofile/fetchTimeZones').catch(() => {
          this.loading = false;
        });
      },
      openGoogleMaps () {
        this.loading = true;
        this.$store.dispatch('projectgates/saveProjectGateDetails', {
          projectGateName: this.projectGateName,
          locationTimeZone: this.locationTimeZone,
          selectedProject: this.selectedProject,
        }).then(() => {
          this.$router.push({ name: 'Google Maps' });
        });
      },
      cancelProjectGateForm () {
        this.$store.dispatch('projectgates/removeSavedProjectGateDetails');
        this.$store.dispatch('projectgates/removeMarkedProjectGateDetails');
        this.$router.push({ name: 'Project Gates' });
      },
      async addProjectGate () {
        if (this.$refs.form.validate() === false || Object.keys(this.markedProjectGateDetails).length === 0) {
         this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('projectgates/addNewProjectGate', {
          projectGateDetails: {
            name: this.projectGateName,
            project_id: this.selectedProject,
            timezone: this.locationTimeZone,
            address: {
              line1: this.markedProjectGateDetails.name,
              line2: this.markedProjectGateDetails.formatted_address,
              city: null,
              state: null,
              country: null,
              postal_code: null,
            },
            latitude: this.markedProjectGateDetails.geometry.location.lat().toString(),
            longitude: this.markedProjectGateDetails.geometry.location.lng().toString(),
          },
        }).then(response => {
            this.$store.dispatch('alert/onAlert', {
            message: 'Project gate added successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
            this.$store.dispatch('projectgates/removeSavedProjectGateDetails');
            this.$store.dispatch('projectgates/removeMarkedProjectGateDetails');
            this.loading = false;
            this.address = {};
            this.$router.push({ name: 'Project Gates' });
          })
          .catch(errors => {
            this.loading = false;
          });
      },
    },
  };
</script>
<style scoped>
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #fff;
  border-radius: 0px !important;
}
.my-base-vcard-style {
  background-color: #fff !important;
}
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
#map-button {
  margin-bottom: 10px;
  font-weight: bold;
}
.codn-div {
  font-size: 15px;
  font-weight: bold;
  color: #37474F;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.codn-chip {
  margin-left: 10px;
}
.mandatory-text {
  margin-left: 20px;
}
.my-card-style {
  border-left: 5px solid #FF3700 !important;
  padding-left: 3px;
}
.border-content{
  border: 1px solid #37474f36;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.font-style-text{
  font-size: 15px;
  color: #37474F;
  text-transform: capitalize;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.font-style-text2{
  font-size: 17px;
  color: #37474F;
  text-transform: capitalize;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
</style>
